
import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpModule, JsonpModule } from '@angular/http';
import { PopoverModule, ModalModule, BsDropdownModule, CollapseModule } from 'ngx-bootstrap';

import { HttpClientModule, HttpClient } from '@angular/common/http'; // Added
// tslint:disable-next-line:max-line-length
import { OAuthModule, AuthConfig } from 'angular-oauth2-oidc'; // Added

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { AppInsights } from 'applicationinsights-js';
import { CookieService } from 'ngx-cookie-service';

import { AppComponent } from './components/app/app.component';
import { HomeComponent } from './components/home/home.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { LoginService } from './services/login.service';
import { UserService } from './services/user-data.service';
import { AppConfig } from './config/app.config';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { PasswordResetComponent } from './components/password-reset/password-reset.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { BaseComponent } from './components/base-component/base-component.component';
import { EmailOptOutComponent } from './components/email-optout/email-optout.component';

import { SAMLConsumerComponent } from './components/SSO/SAML/SAML-consumer.component';

import { ValidUserDirective } from './directives/valid-user.directive';

import { TipWebID } from './components/tipweb-id/tipweb-id.component';
import { OauthConsumerComponent } from './components/SSO/OAuth2/oauth-consumer.component';

import { Angulartics2Module } from 'angulartics2';
import { NoDistrictLandingComponent } from './components/no-district-landing/no-district-landing.component';
import { SettingsService } from './services/settings.service';
import { GatewayComponent } from './components/gateway/gateway.component';

@NgModule({
   declarations: [
      AppComponent,
      BaseComponent,
      HomeComponent,
      LandingPageComponent,
      ForgotPasswordComponent,
      PasswordResetComponent,
      SAMLConsumerComponent,
      SpinnerComponent,
      ValidUserDirective,
      TipWebID,
      OauthConsumerComponent,
      EmailOptOutComponent,
      NoDistrictLandingComponent,
      GatewayComponent
   ],
   imports: [
      BrowserModule,
      ReactiveFormsModule,
      FormsModule,
      HttpModule,
      HttpClientModule,
      JsonpModule,
      ModalModule.forRoot(),
      OAuthModule.forRoot(),
      BrowserAnimationsModule, // required animations module
      ToastrModule.forRoot({ positionClass: 'toast-bottom-right' }),
      RouterModule.forRoot([
         { path: 'no-district-landing', component: NoDistrictLandingComponent },
         { path: 'home', component: HomeComponent },
         { path: 'landing', component: LandingPageComponent },
         { path: 'passwordreset/:request', component: PasswordResetComponent },
         { path: 'SAML', component: SAMLConsumerComponent },
         { path: 'OAuth', component: OauthConsumerComponent },
         { path: 'tipweb-id/:userId', component: TipWebID, outlet: 'popUpOutlet' },
         { path: 'optOut/:code', component: EmailOptOutComponent },
         { path: 'gateway/:authcode', component: GatewayComponent },
         { path: '', redirectTo: 'landing', pathMatch: 'full' },
         { path: '**', redirectTo: 'landing' }
      ]),
      Angulartics2Module.forRoot()
   ],
   providers: [
      LoginService,
      UserService,
      AppConfig,
      Title,
      CookieService,
      SettingsService,
      // {
      //   provide: APP_INITIALIZER,
      //   useFactory: (config: AppConfig) => () => config.load(),
      //   deps: [AppConfig],
      //   multi: true
      // }
   ],
   bootstrap: [AppComponent]
})
export class AppModule {
}
