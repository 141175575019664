import { Component, OnInit } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { SettingsService } from 'app/services/settings.service';
import { CookieService } from 'ngx-cookie-service';
import { ILogin } from '../../models/login';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-gateway',
  templateUrl: './gateway.component.html',
  styleUrls: ['./gateway.component.css']
})
export class GatewayComponent implements OnInit {
  public authToken;
  finalDestination: string;
  public expireCookieDate: Date = new Date();
  public districtName: string = '';
  public firebaseCollectionName: string = '';
  public showDataWarning: boolean = false;
  public assetManagementType: number;
  public gotDistrictName: boolean = false;
  public gotLoginType: boolean = false;
  public showError: boolean = false;
  private loginType: any;
  public isRequesting: boolean = true;
  public displayMode: string = 'WebView';

  Login: ILogin = {
    loginName: null,
    userID: null,
    password: null,
    requestEntityID: false,
    userTypeID: null,
    routeUrl: null,
    returnUrl: null,
    hayesToken: null,
    refreshToken: null
  };
  public responseData: ILogin = {
    loginName: null,
    userID: null,
    password: null,
    requestEntityID: false,
    userTypeID: null,
    routeUrl: null,
    returnUrl: null,
    hayesToken: null,
    refreshToken: null
  };

  constructor(private _route: ActivatedRoute, private _loginService: LoginService, private _cookieService: CookieService, private _settingsService: SettingsService) {

    //Clear all existing cookies & localstorage data
    this._cookieService.deleteAll('/');
    localStorage.clear();

    this._route.params.forEach((params: Params) => {
      if (params['authcode']) {
        this.authToken = params['authcode'];
        // Get the District Name, Type of login provider and settings for Guest
        this.getSystemSettingsValidateToken();
      }
      else {
        window.location.href = '/landing';
      }
    });
  }

  ngOnInit() {
  }

  getSystemSettingsValidateToken() {

    this._settingsService.GetSystemSettings().then(
      (data) => {

        this.expireCookieDate.setDate(this.expireCookieDate.getDate() + 7);
        this.districtName = data.districtName;
        this._cookieService.set('districtName', this.districtName, this.expireCookieDate, '/');
        this.gotDistrictName = true;

        this.loginType = data.loginType;
        this.gotLoginType = true;

        this.assetManagementType = data.assetManagementType;
        this._cookieService.set('assetManagementType', this.assetManagementType.toString(), this.expireCookieDate, '/');

        this.firebaseCollectionName = data.firebaseNotificationsCollection;
        this._cookieService.set('firebaseCollection', this.firebaseCollectionName, this.expireCookieDate, '/');



        this._loginService.ValidateToken(this.authToken).then( // subscribe(
          data => {
            this.responseData = data;

            this._cookieService.set('hhduiUrl', this.responseData.routeUrl, this.expireCookieDate, '/');

            const api = encodeURIComponent(this._loginService.getApiUrl());
            this.finalDestination = this._route.snapshot.queryParams.d ? this._route.snapshot.queryParams.d : undefined;

            if (this.responseData.routeUrl && this.responseData.routeUrl.includes('localhost')) {
              const d = this.finalDestination ? '&d=' + this.finalDestination : '';
              const encodedUri = encodeURI(this.responseData.routeUrl +
                'splash?userName=' + this.responseData.loginName +
                '&userId=' + this.responseData.userID +
                '&userType=' + this.responseData.userTypeID +
                '&districtName=' + this.districtName +
                '&assetManagementType=' + this.assetManagementType.toString() +
                '&firebaseCollection=' + this.firebaseCollectionName +
                '&apiToken=' + this.responseData.hayesToken +
                '&refreshToken=' + this.responseData.refreshToken) +
                '&login=' + this.responseData.returnUrl +
                '&displayMode=' + this.displayMode +
                '&api=' + api + d;

              this._loginService.redirectUrl = encodedUri;
            } else {
              const d = this.finalDestination ? this.finalDestination : '';
              let localData = {
                jwt: this.responseData.hayesToken,
                refreshToken: this.responseData.refreshToken,
                userName: this.responseData.loginName,
                userId: this.responseData.userID,
                userType: this.responseData.userTypeID,
                districtName: this.districtName,
                assetManagementType: this.assetManagementType.toString(),
                firebaseCollection: this.firebaseCollectionName,
                login: this.responseData.returnUrl,
                api: api,
                d: d,
              };
              this._loginService.setLocalData(localData);
              const encodedUri = encodeURI(this.responseData.routeUrl + 'splash?userId=' + this.responseData.userID);
              this._loginService.redirectUrl = encodedUri;
            }
            
            this._loginService.hayesToken = this.responseData.hayesToken;
            this._loginService.refreshToken = this.responseData.refreshToken;
            this.isRequesting = false;
            window.location.href = this._loginService.redirectUrl;
          },
          error => {
            console.log('Gateway - Validate auth token call failed');
            console.dir(error);
          }
        );
      },
      (error) => {
        console.log('Gateway - Get System Settings call failed');
        console.dir(error);
        this.isRequesting = false;
      }
    );

  }

}
