import { Component } from '@angular/core';
import { TelemetryService } from '../../services/telemetry-tracker.service';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [TelemetryService]
})

export class AppComponent {
  constructor(
     private _telemetryService: TelemetryService,
     private ga: Angulartics2GoogleAnalytics
     ) {
      if (environment && environment.appAnalytics && environment.appAnalytics.id) {
         let script = document.createElement("script");
         script.type = "text/javascript";
          script.async = true;
          script.src = "//www.googletagmanager.com/gtag/js?id=" + environment.appAnalytics.id;
          document.head.appendChild(script);

         script = document.createElement('script');
         script.text = `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${environment.appAnalytics.id}');
            `;

         document.head.appendChild(script);
         this.ga.startTracking();
      }
  }
}
