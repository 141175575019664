import { Component, OnInit, AfterViewChecked } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser'

import { BaseComponent } from '../base-component/base-component.component'

import { LoginService } from '../../services/login.service';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'password-reset',
    templateUrl: './password-reset.component.html',
    styleUrls: ['./password-reset.component.css'],
    providers: [LoginService]
})

export class PasswordResetComponent extends BaseComponent implements OnInit, AfterViewChecked {

    public changePasswordForm: FormGroup;
    public passwordChange: any = {
        newPassword: null,
        confirmNewPassword: null,
        userID: null
    };

    public optionalParams: any = {};
    public checkingNewPasswordsMatch: boolean;
    public newPasswordValid: boolean;
    public requestCode: string;
    public passwordFieldsData: boolean;
    public isRequesting: boolean = false;
    public version: string = environment.version;

    formErrors: any = {
        error: ''
    };

    formErrorsList: any[] = [];

    validationMessages = {
        newPassword: { 'required': 'New Password field is required.' },
        confirmNewPassword: { 'required': 'Must confirm new password.' }
    };

    successMessage = {}

    constructor(private _fb: FormBuilder,
        private _ls: LoginService,
        private _toastr: ToastrService,
        private _router: Router,
        private _route: ActivatedRoute,
        private _titleService: Title) {
        super();
        const urlParams = this._route.snapshot.params;
        const userId = this._route.snapshot.queryParamMap.get('u');

        if (urlParams['request'] && userId) {
            this.requestCode = urlParams['request'];

            this.passwordChange.userId = userId;
            this.passwordFieldsData = false;
        } else {
            this._toastr.error('Password reset infomration Missing.', 'Uh oh!');
            this._router.navigate(['home']);
        }
    }

    ngAfterViewChecked() {

        const newTitle = `Help Desk - ${this.version} - Change Password`;
        this._titleService.setTitle(newTitle);

    }

    ngOnInit() {

        this.buildForm();
    }

    buildForm(): void {

        this.changePasswordForm = this._fb.group({
            'newPassword': [this.passwordChange.newPassword],
            'confirmNewPassword': [this.passwordChange.confirmNewPassword],
            'userId': [this.passwordChange.userId]
        });

        this.changePasswordForm.valueChanges.subscribe(data => this.onValueChanged(data));
    }

    onValueChanged(data?: any) {
        if (!this.changePasswordForm) {
            return;
        }

        const form = this.changePasswordForm;

        if (form.controls['newPassword'].value && form.controls['confirmNewPassword'].value) {
            this.passwordFieldsData = true;
        } else {
            this.passwordFieldsData = false;
        }

        this.optionalParams = { confirmVal: form.controls['newPassword'].value };

    }

    onCancel() {

        this._router.navigate(['home']);

    }

    onSubmit(): void {

        this.isRequesting = true;

        if (this.passwordValidations()) {
            const passwordsNotMatch =
                this.checkNewPasswordsNotMatching(
                    this.changePasswordForm.controls['newPassword'].value,
                    this.changePasswordForm.controls['confirmNewPassword'].value);

            if (!passwordsNotMatch) {
                this.passwordChange = this.changePasswordForm.value;
                const payload = { userID: this.passwordChange.userId, resetCode: this.requestCode, newPassword: this.passwordChange.newPassword }

                this._ls.updatePassword(payload).then(
                    data => {
                        console.log(data);

                        this._toastr.success('Password updated successfully!', 'Yay!')
                        this._router.navigate(['home']);
                    },
                    error => {
                        console.log(error._body);
                        this._toastr.error('Please make a new request to reset your password.', 'Password Request Expired');
                        this._router.navigate(['home']);
                    });
            } else {
                this._toastr.error('Password confirmation does not match.', 'Password Mismatch');
            }
        }

        this.isRequesting = false;
    }

    passwordValidations(): boolean {
        const newPassword: String = this.changePasswordForm.controls['newPassword'].value;

        if (newPassword.length < 8) {
            this._toastr.error('New Password must be at least 8 characters in length.', 'Invalid Password');
            return false;
        } else {
            return true;
        }
    }

    checkNewPasswordsNotMatching(value: any, optionalParams: any): boolean {

        if (optionalParams && value === optionalParams) {
            return false;
        } else {
            return true;
        }
    }
}
