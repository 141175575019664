
import {throwError as observableThrowError,  Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions, Headers } from '@angular/http';



import { ILogin } from '../models/login';
import { AppConfig } from '../config/app.config';

@Injectable()
export class LoginService {

   public apiUrl = '';
   public apiControllerUrl = 'Login/';
   private apiconfig: AppConfig;
   public options: RequestOptions;

   // Properties usde for shared service across all App
   public redirectUrl: string;
   public hayesToken: string;
   public refreshToken: string;

   constructor(private _http: Http, private config: AppConfig) {
      this.apiconfig = config;
   }

   setLocalData(data: any) {
      localStorage.setItem("jwt", data.jwt);
      localStorage.setItem("refreshToken", encodeURI(data.refreshToken));
      localStorage.setItem("userName", data.userName);
      localStorage.setItem("userId", data.userId);
      localStorage.setItem("userType", data.userType);
      localStorage.setItem("districtName", data.districtName);
      localStorage.setItem("assetManagementType", data.assetManagementType);
      localStorage.setItem("firebaseCollection", data.firebaseCollection);
      localStorage.setItem("login", decodeURIComponent(data.login));
      localStorage.setItem("api", decodeURIComponent(data.api));
      localStorage.setItem("d", data.d);
   }

   public checkIsValidLogin(login: ILogin): boolean {
      return false;
   }

   private setApiOptions() {

      const headers = new Headers({ 'Content-Type': 'application/json' });

      this.options = new RequestOptions({ headers: headers });

   }

   private readConfig(): Promise<any> {
      this.setApiOptions();

      const configPromise = this.config.readConfig();

      return configPromise.then(
         (data) => {
            if (data) {
               console.log(data);
               console.log(data.apiUrl);

               if (data) {
                  this.apiUrl = data.apiUrl;
               }
            } else {
               console.log('no config');
            }
         },
         (error) => console.log(error)
      );
   }

   public Login(login: ILogin): Promise<any> {
      return this.readConfig().then(
         (data) => {
            const apiUrlRoute = this.apiUrl + this.apiControllerUrl + 'ValidateLogin';

            const body = { UserName: login.loginName, Password: login.password };

            const promise = new Promise((resolve, reject) => {

               this._http.post(apiUrlRoute, body, this.options).toPromise().then(
                  (res) => {
                     if (res && res.status === 204) {
                        reject(this.extractData(res));
                     } else {
                        resolve(this.extractData(res));
                     }
                  },
                  (msg) => {
                     reject(this.extractData(msg));
                  });
            });
            return promise;
         },
         (error) => {
            console.log('Settings Service - GetDistrictName promise failed');
            return Promise.reject('Failed to read configuration');
         }
      );
   }

   public getApiUrl() {
      return this.apiUrl;
   }

   private extractData(res: Response) {
      if (res && res.status === 204) {
         return 'Invalid Username or Password. Please try again.';
      } else if (res) {
         const body = res.json();
         const token = body.hayesToken;
         const refreshToken = body.refreshToken;
         localStorage.setItem("jwt", token); 
         localStorage.setItem("refreshToken", refreshToken);
         return body || {};
      } else {
         return 'Invalid Request';
      }
   }

   private extractTextResult(res: Response) {
      if (res && res.status === 200) {
         const body = res.text;
         return body;
      } else {
         return 'Invalid Request';
      }
   }

   /**
    * Update user password by Request Code
    */
   public updatePassword(passwordData: any): Promise<any> {
      // this.readConfig();
      return this.readConfig().then(
         (data) => {
            const apiUrlRoute = this.apiUrl + this.apiControllerUrl + 'ResetPassword';

            const body = JSON.stringify(passwordData);

            const promise = new Promise((resolve, reject) => {
               this._http.put(apiUrlRoute, body, this.options).toPromise().then(
                  (res) => {
                     if (res && res.status === 204) {
                        reject(this.extractTextResult(res));
                     } else {
                        resolve(this.extractTextResult(res));
                     }
                  },
                  (msg) => {
                     reject(this.errorResponse(msg));
                  });
            });
            return promise;
         },
         (error) => {
            console.log('Settings Service - updatePassword promise failed');
            return Promise.reject(this.errorResponse(error));
         }
      );
   }

   public requestPasswordResetFromEmail(email: string) {
      // based on if email exists, we can reset the password for the account
      // this.readConfig();
      return this.readConfig().then(
         (data) => {
            const apiUrlRoute = this.apiUrl + this.apiControllerUrl + 'ForgotPassword';
            const body = { Email: email };

            const promise = new Promise((resolve, reject) => {
               return this._http.post(apiUrlRoute, JSON.stringify(body), this.options).toPromise().then(
                  (res) => {
                     if (res && res.status === 204) {
                        reject(this.extractTextResult(res));
                     } else {
                        resolve(this.extractTextResult(res));
                     }
                  },
                  (msg) => {
                     reject(this.errorResponse(msg));
                  });
            });
            return promise;
         },
         (error) => {
            console.log('Settings Service - requestPasswordResetFromEmail promise failed');
            return Promise.reject(this.errorResponse(error));
         }
      );
   }

   public ValidateToken(token: string): Promise<any> {
      return this.readConfig().then(
         (data) => {
            const apiUrlRoute = this.apiUrl + 'OAuth/ValidateToken';
            const body = { AuthToken: token };

            const promise = new Promise((resolve, reject) => {

               this._http.post(apiUrlRoute, body, this.options).toPromise().then(
                  (res) => {
                     if (res && res.status === 204) {
                        reject(this.extractData(res));
                     } else {
                        resolve(this.extractData(res));
                     }
                  },
                  (msg) => {
                     if (msg && msg.status === 401) {
                        window.location.href = '/landing';
                     }
                     else{
                        reject(this.extractData(msg));
                     }
                  });
            });
            return promise;
         },
         (error) => {
            console.log('Settings Service - GetDistrictName promise failed');
            return Promise.reject('Failed to read configuration');
         }
      );
   }

   private errorResponse(error: Response | any) {
      // In a real world app, we might use a remote logging infrastructure
      let errMsg: string;
      if (error instanceof Response) {
         const body = error.json() || '';
         const err = body.error || JSON.stringify(body);
         errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
      } else {
         errMsg = error.message ? error.message : error.toString();
      }

      console.error(errMsg);
      return observableThrowError(errMsg);
   }
}
