import { Component, OnInit, AfterViewChecked } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';

import { BaseComponent } from '../../base-component/base-component.component';

import { ILogin } from '../../../models/login';
import { AppConfig } from '../../../config/app.config';
import { LoginService } from '../../../services/login.service';
import { SSOService } from '../../../services/sso.service';
import { environment } from '../../../../environments/environment';
import { SettingsService } from 'app/services/settings.service';

@Component({
   selector: 'SAML',
   templateUrl: './SAML-consumer.component.html',
   styleUrls: ['./SAML-consumer.component.css'],
   providers: [SSOService, SettingsService]
})

export class SAMLConsumerComponent extends BaseComponent implements OnInit, AfterViewChecked {

   public responseData: ILogin;
   public finalDestination: string;
   public samlToken: string;
   public samlSignature: string;
   public isRequesting: boolean = false;
   public samlError: string;
   public isError: boolean = false;
   public showSAML: boolean = false;

   private apiUrl: string;
   public version: string = environment.version;
   public assetManagementType: number;
   public districtName: string;
   public firebaseCollection: string;
   public expireCookieDate: Date = new Date();
   public refreshToken: string;

   constructor(
      private _apiconfig: AppConfig,
      private _loginService: LoginService,
      private _route: ActivatedRoute,
      private _router: Router,
      private _ssoService: SSOService,
      private _titleService: Title,
      private _settingsService: SettingsService,
      private _cookieService: CookieService) {

      super();
      this.expireCookieDate = new Date();
      this.expireCookieDate.setDate(this.expireCookieDate.getDate() + 7);
      // let urlParams = this._route.snapshot.params;
      this.samlToken = this._route.snapshot.queryParamMap.get('SAMLResponse');
      this.samlSignature = this._route.snapshot.queryParamMap.get('Signature');

      if (this.samlToken) {

         this.isRequesting = true;

         this._ssoService.VerifySAML(this.samlToken, this.samlSignature).then(
            data => {
               this._settingsService.GetSystemSettings().then(
                  (settingsData) => {

                     this.responseData = data;
                     this._cookieService.set('hhduiUrl', this.responseData.routeUrl, this.expireCookieDate, '/');

                     this.assetManagementType = settingsData.assetManagementType;
                     this.districtName = settingsData.districtName;
                     this.firebaseCollection = settingsData.firebaseNotificationsCollection;

                     this._cookieService.set('districtName', this.districtName, this.expireCookieDate, '/');
                     this._cookieService.set('firebaseCollection', this.firebaseCollection, this.expireCookieDate, '/');
                     this._cookieService.set('assetManagementType', this.assetManagementType.toString(), this.expireCookieDate, '/');

                     this.setRedirectURL(this.responseData.routeUrl);

                     if (this.responseData.requestEntityID === true && this.assetManagementType === 1) {
                        this._router.navigate([{ outlets: { popUpOutlet: 'tipweb-id/' + this.responseData.userID } }]);
                     } else {
                        window.location.href = this._loginService.redirectUrl;
                     }
                  },
                  (error) => {
                     this.isError = true;
                     this.isRequesting = false;

                     this.samlError = 'Single Sign On (SSO) error.\n' +
                        'Unable to process your Login request.\n' +
                        'Please contact technical support with the following information:\n\n';
                     this.samlError = this.samlError + error;
                  });
            },
            error => {
               this.isError = true;
               this.isRequesting = false;

               this.samlError = 'Single Sign On (SSO) error.\n' +
                  'Unable to process your Login request.\n' +
                  'Please contact technical support with the following information:\n\n';
               this.samlError = this.samlError + error;
            }
         );

      } else {
         // this._toastr.error('Password reset infomration Missing.', 'Uh oh!');
         this._router.navigate(['landing']);
      }
   }

   ngAfterViewChecked() {
      const newTitle = `Help Desk - ${this.version} - SSO`;
      this._titleService.setTitle(newTitle);
   }

   ngOnInit(): void {
      this.finalDestination = this._route.snapshot.queryParams.d ? this._route.snapshot.queryParams.d : undefined;

      this._apiconfig.readConfig().then(
         (data) => {
            if (data) {
               if (data) {
                  this.apiUrl = data.apiUrl;
               }
            } else {
               console.log('no config');
            }
         },
         (error) => console.log(error)
      );
   }

   private setRedirectURL(newHref: string) {

      const api = encodeURIComponent(this.apiUrl);
      const d = this.finalDestination ? this.finalDestination : '';
      const loginUrl = window.location.href.indexOf('?') > 0 ?
         encodeURIComponent(window.location.href.substring(0, window.location.href.indexOf('?'))) :
         encodeURIComponent(window.location.href);
         
      let localData = {
         jwt: this.responseData.hayesToken,
         refreshToken: this.responseData.refreshToken,
         userName: this.responseData.loginName,
         userId: this.responseData.userID,
         userType: this.responseData.userTypeID,
         districtName: this.districtName,
         assetManagementType: this.assetManagementType.toString(),
         firebaseCollection: this.firebaseCollection,
         login: loginUrl,
         api: api,
         d: d,
      };
      this._loginService.setLocalData(localData);

      const encodedUri = encodeURI(newHref + 'splash?userId=' + this.responseData.userID);
      this._loginService.redirectUrl = encodedUri;
      this._loginService.hayesToken = this.responseData.hayesToken;
      this._loginService.refreshToken = this.responseData.refreshToken;
   }
}
