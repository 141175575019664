// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
   version: require('../../package.json').version,
   production: false,
   appInsights: {
      instrumentationKey: '4975c11c-7881-402f-947d-aea3b860841a'
   },
   appAnalytics: {
      id: 'G-NR2SRNJ1MX'
   }
};
