
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions, Headers } from '@angular/http';



import { AppConfig } from '../config/app.config';

@Injectable()
export class UserService {

   public apiUrl = '';
   public apiControllerUrl = 'TIPWeb/';
   private apiconfig: AppConfig;
   public options: RequestOptions;

   constructor(private _http: Http, private config: AppConfig) {
      this.apiconfig = config;
   }

   private setApiOptions(token: string) {
      const headers = new Headers({ 'Content-Type': 'application/json' });
      headers.append('Authorization', 'Bearer ' + token);

      this.options = new RequestOptions({ headers: headers });
   }

   private readConfig(): Promise<any> {
      const configPromise = this.config.readConfig();

      return configPromise.then(
         (data) => {
            if (data) {
               console.log(data);
               console.log(data.apiUrl);

               if (data) {
                  this.apiUrl = data.apiUrl;
               }
            } else {
               console.log('no config');
            }
         },
         (error) => console.log(error)
      );
   }

   /**
    * request verification of Entity ID
    */
   public verifyStaffStudentID(entityIDRequest: any): Promise<any> {

      this.setApiOptions(entityIDRequest.hayesToken);

      return this.readConfig().then(
         (data) => {
            const apiUrlRoute = this.apiUrl + this.apiControllerUrl + 'VerifyStaffStudentID';

            const body = JSON.stringify(entityIDRequest);

            const promise = new Promise((resolve, reject) => {
               this._http.put(apiUrlRoute, body, this.options).toPromise().then(
                  (res) => {
                     if (res && res.status === 204) {
                        reject(this.extractTextResult(res));
                     } else {
                        resolve(this.extractTextResult(res));
                     }
                  },
                  (msg) => {
                     reject(this.errorResponse(msg));
                  });
            });
            return promise;
         },
         (error) => {
            console.log('verifyStaffStudentID - promise failed ');
            return Promise.reject(this.errorResponse(error));
         }
      );
   }

   /**
    * update Entity ID on user account
    */
   public updateStaffStudentID(entityIDRequest: any): Promise<any> {
      this.setApiOptions(entityIDRequest.hayesToken);

      return this.readConfig().then(
         (data) => {
            const apiUrlRoute = this.apiUrl + this.apiControllerUrl + 'UpdateStaffStudentID';

            const body = JSON.stringify(entityIDRequest);

            const promise = new Promise((resolve, reject) => {
               this._http.put(apiUrlRoute, body, this.options).toPromise().then(
                  (res) => {
                     if (res && res.status === 204) {
                        reject(this.extractTextResult(res));
                     } else {
                        resolve(this.extractTextResult(res));
                     }
                  },
                  (msg) => {
                     reject(this.errorResponse(msg));
                  });
            });
            return promise;
         },
         (error) => {
            console.log('verifyStaffStudentID - promise failed ');
            return Promise.reject(this.errorResponse(error));
         }
      );
   }

   private extractTextResult(res: Response) {
      if (res && res.status === 200) {
         const body = res.text();
         return body;
      } else {
         return 'Invalid Verification Request';
      }
   }

   private errorResponse(error: Response | any) {
      // In a real world app, we might use a remote logging infrastructure
      let errMsg: string;
      if (error instanceof Response) {
         const body = error.json() || '';
         const err = body.error || JSON.stringify(body);
         errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
      } else {
         errMsg = error.message ? error.message : error.toString();
      }

      console.error(errMsg);
      return observableThrowError(errMsg);
   }
}
