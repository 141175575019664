import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
   selector: 'app-no-district-landing',
   templateUrl: './no-district-landing.component.html',
   styleUrls: ['./no-district-landing.component.css']
})
export class NoDistrictLandingComponent implements OnInit {
   public givenUrl: string;
   constructor(
      private _router: Router
   ) { }

   ngOnInit() {
   }

   routeToUrl(url) {
      console.log(url);
      console.log(window);
      // this._router.navigateByUrl(url);
      window.location.host = url;
      window.location.href = url;
   }

   updateUrlValue(event) {
      this.givenUrl = event.target.value;
   }

}
