import { Component, OnInit, OnChanges, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import { BaseComponent } from '../base-component/base-component.component';

import { LoginService } from '../../services/login.service';

@Component({
    selector: 'forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.css'],
    providers: [LoginService]
})

export class ForgotPasswordComponent extends BaseComponent implements OnInit, OnChanges {

    isRequesting: boolean;
    @Output() resetSent = new EventEmitter<any>();

    forgotPasswordElement: any = {
        email: null
    };

    forgotPasswordForm: FormGroup;

    formErrors = {
        'email': '',
    };

    validationMessages = {
        'email': { 'required': 'Cannot reset password without email.' }
    };

    constructor(private _fb: FormBuilder,
        private _ls: LoginService,
        private _toastr: ToastrService) {
        super();
    }

    private stopRefreshing() {
        this.isRequesting = false;
    }

    ngOnInit() {
        this.buildForm();
    }

    ngOnChanges() {
        this.buildForm();
    }

    buildForm(): void {
        this.forgotPasswordForm = this._fb.group({
            'email': [this.forgotPasswordElement.email, [Validators.required, Validators.nullValidator]]
        });

        this.forgotPasswordForm.valueChanges.subscribe(data => this.onValueChanged(data));

        this.onValueChanged();
    }

    onValueChanged(data?: any) {
        if (!this.forgotPasswordForm) {
            return;
        }
    }

    onSubmit(): void {
        this.forgotPasswordElement = this.forgotPasswordForm.controls['email'].value;

        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const validEmail = regex.test(this.forgotPasswordElement);

        if (!validEmail) {
            this._toastr.error('A valid e-mail is required.', 'Uh oh!');
            return;
        }

        this.isRequesting = true;

        this._ls.requestPasswordResetFromEmail(this.forgotPasswordElement).then(
            data => {
                this.resetSent.emit(true);
                this.forgotPasswordForm.reset();
                this.stopRefreshing();
                this._toastr.success('An e-mail has been sent with instructions on how to reset your password.', 'Password E-Mail Sent');
            },
            error => {
                if (error.error === '530 - OK "User account inactive."') {
                  this.resetSent.emit(true);
                  this.forgotPasswordForm.reset();
                  this.stopRefreshing();
                  this._toastr.success('An e-mail has been sent with instructions on how to reset your password.', 'Password E-Mail Sent');
                } else {
                  this._toastr.error('There was an error requesting a password reset.', 'Uh oh!');
                  this.stopRefreshing();
                }

            }
        );
    }
}
