import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AppInsights } from 'applicationinsights-js';

@Injectable()
export class TelemetryService {

    protected config: Microsoft.ApplicationInsights.IConfig = {
        instrumentationKey: environment.appInsights.instrumentationKey
    };

    constructor() {
        console.log('Configuration extracted with Instrumentation Key: ' + this.config.instrumentationKey);
        if (!AppInsights.config) {
            if (this.config.instrumentationKey) {
                console.log('################### Instrumentation Key Configuration: ' + this.config.instrumentationKey + ' ###################');
                AppInsights.downloadAndSetup(this.config);
            } else {
                console.log('################### No Instrumentation Key configured ###################');
            }
        }
    }

    logPageView(name?: string, url?: string, properties?: any, measurements?: any, duration?: number) {
        if (this.config.instrumentationKey) {
            console.log('Log Page View');
            AppInsights.trackPageView(name, url, properties, measurements, duration);
        }
    }

    trackEvent(name: string, properties?: any, measurements?: any) {
        if (this.config.instrumentationKey) {
            AppInsights.trackEvent(name, properties, measurements);
        }
    }

    trackException(exception: Error, handledAt?: string, properties?: any, measurements?: any, severityLevel?: AI.SeverityLevel) {
        if (this.config.instrumentationKey) {
            AppInsights.trackException(exception, handledAt, properties, measurements, severityLevel);
        }
    }

    trackDependency(id: string, method: string, absoluteUrl: string, pathName: string, totalTime: number, success: boolean, resultCode: number) {
        if (this.config.instrumentationKey) {
            AppInsights.trackDependency(id, method, absoluteUrl, pathName, totalTime, success, resultCode);
        }
    }
}
