import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';

import { OAuthService, JwksValidationHandler, AuthConfig } from 'angular-oauth2-oidc';

import { BaseComponent } from '../base-component/base-component.component';

import { SettingsService } from '../../services/settings.service';
import { SSOService } from '../../services/sso.service';

import { environment } from '../../../environments/environment';
import { LoginService } from 'app/services/login.service';

@Component({
   selector: 'landing-page',
   templateUrl: './landing-page.component.html',
   styleUrls: ['./landing-page.component.css'],
   providers: [SettingsService, SSOService]
})
export class LandingPageComponent extends BaseComponent implements OnInit {

   public isRequesting: boolean = true;
   public districtName: string = '';
   public firebaseCollectionName: string = '';
   public showDataWarning: boolean = false;
   private loginType: any;
   public assetManagementType: number;
   public gotDistrictName: boolean = false;
   public gotLoginType: boolean = false;
   public responseData: any;
   public showError: boolean = false;

   public message: string;
   private finalDestination: string;
   public expireCookieDate: Date = new Date();

   public authConfig: AuthConfig = {
      strictDiscoveryDocumentValidation: false
   };

   public version: string = environment.version;

   constructor(
      private _router: Router,
      private _route: ActivatedRoute,
      private _settingsService: SettingsService,
      private _ssoService: SSOService,
      private _titleService: Title,
      private _cookieService: CookieService,
      private _oauthService: OAuthService,
      private _loginService: LoginService) {
      super();

      const title = `Help Desk - ${this.version} - Login`;
      this._titleService.setTitle(title);
      this.expireCookieDate = new Date();
      this.expireCookieDate.setDate(this.expireCookieDate.getDate() + 7);
   }

   ngOnInit() {
      // Get the District Name, Type of login provider and settings for Guest
      this.getLandingPageData();

      this.finalDestination = this._route.snapshot.queryParams.d ? this._route.snapshot.queryParams.d : undefined;

      if (this.finalDestination !== undefined) {
         this._cookieService.set('finalDestination', this.finalDestination, this.expireCookieDate, '/');
      }

      if (this.validCookies()) {
         this.redirectViaCookies();
      }
   }

   getLandingPageData() {

      this._settingsService.GetSystemSettings().then(
         (data) => {
            this.districtName = data.districtName;
            this._cookieService.set('districtName', this.districtName, this.expireCookieDate, '/');
            this.gotDistrictName = true;

            this.loginType = data.loginType;
            this.gotLoginType = true;

            this.assetManagementType = data.assetManagementType;
            this._cookieService.set('assetManagementType', this.assetManagementType.toString(), this.expireCookieDate, '/');

            this.firebaseCollectionName = data.firebaseNotificationsCollection;
            this._cookieService.set('firebaseCollection', this.firebaseCollectionName, this.expireCookieDate, '/');

            if (this.gotLoginType) {
               this.isRequesting = false;
            }

         },
         (error) => {
            console.log('Landing Page - Get System Settings call failed');
            console.dir(error);
            this.isRequesting = false;
         }
      );

   }

   private validCookies(): boolean {

      const cookies = {
         userName: this._cookieService.get('userName'),
         userType: this._cookieService.get('userType'),
         userID: this._cookieService.get('userID'),
         apiToken: this._cookieService.get('apiToken'),
         expired: this._cookieService.get('expired'),
         apiUrl: this._cookieService.get('apiUrl'),
         loginUrl: this._cookieService.get('loginUrl'),
         hhduiUrl: this._cookieService.get('hhduiUrl'),
         districtName: this._cookieService.get('districtName'),
         firebaseCollection: this._cookieService.get('firebaseCollection'),
         refreshToken: this._cookieService.get('refreshToken')
      };

      if (!this.isCookieEmpty(cookies.expired) && cookies.expired !== '1' &&
         !this.isCookieEmpty(cookies.userName) &&
         !this.isCookieEmpty(cookies.userID) &&
         !this.isCookieEmpty(cookies.userType) &&
         !this.isCookieEmpty(cookies.apiToken) &&
         !this.isCookieEmpty(cookies.apiUrl) &&
         !this.isCookieEmpty(cookies.loginUrl) &&
         !this.isCookieEmpty(cookies.districtName) &&
         !this.isCookieEmpty(cookies.firebaseCollection) &&
         !this.isCookieEmpty(cookies.hhduiUrl) &&
         !this.isCookieEmpty(cookies.refreshToken)) {

         return true;
      } else {
         this._cookieService.delete('userName', '/');
         this._cookieService.delete('userType', '/');
         this._cookieService.delete('userID', '/');
         this._cookieService.delete('apiToken', '/');
         this._cookieService.delete('refreshToken', '/');
         return false;
      }
   }

   private isCookieEmpty(cookie: string): boolean {
      return cookie === undefined || cookie === null || cookie === '';
   }

   private redirectViaCookies() {

      const cookies = {
         userName: this._cookieService.get('userName'),
         userType: this._cookieService.get('userType'),
         userID: this._cookieService.get('userID'),
         apiToken: this._cookieService.get('apiToken'),
         expired: this._cookieService.get('expired'),
         apiUrl: this._cookieService.get('apiUrl'),
         loginUrl: this._cookieService.get('loginUrl'),
         hhduiUrl: this._cookieService.get('hhduiUrl'),
         districtName: this._cookieService.get('districtName'),
         assetManagementType: this._cookieService.get('assetManagementType'),
         firebaseCollection: this._cookieService.get('firebaseCollection'),
         refreshToken: this._cookieService.get('refreshToken')
      };

      const loginUrl = window.location.href.indexOf('?') > 0 ?
         encodeURIComponent(window.location.href.substring(0, window.location.href.indexOf('?'))) :
         encodeURIComponent(window.location.href);

      const api = cookies.apiUrl;

      if (cookies.hhduiUrl && cookies.hhduiUrl.includes('localhost')) {
         const d = this.finalDestination ? '&d=' + this.finalDestination : '';
         const encodedUri = encodeURI(cookies.hhduiUrl +
            'splash?userName=' + cookies.userName +
            '&userId=' + cookies.userID +
            '&userType=' + cookies.userType +
            '&districtName=' + cookies.districtName +
            '&assetManagementType=' + cookies.assetManagementType +
            '&firebaseCollection=' + cookies.firebaseCollection +
            '&apiToken=' + cookies.apiToken +
            '&refreshToken=' + cookies.refreshToken) +
            '&login=' + loginUrl +
            '&api=' + api + d;

         window.location.href = encodedUri;
      } else {
         const d = this.finalDestination ? this.finalDestination : '';
         let localData = {
            jwt: cookies.apiToken,
            refreshToken: cookies.refreshToken,
            userName: cookies.userName,
            userId: cookies.userID,
            userType: cookies.userType,
            districtName: cookies.districtName,
            assetManagementType: cookies.assetManagementType.toString(),
            firebaseCollection: cookies.firebaseCollection,
            login: loginUrl,
            api: api,
            d: d,
         };
         this._loginService.setLocalData(localData);
         const encodedUri = encodeURI(cookies.hhduiUrl + 'splash?userId=' + cookies.userID);
         window.location.href = encodedUri;
      }

   }

   onSignInClick() {
      if (this.loginType === 0) { // Normal
         // console.log('onSignInClick on Landing Page');
         if (this.validCookies()) {
            this.redirectViaCookies();
         } else {
            this._router.navigate(['/home']);
         }
      } else if (this.loginType === 1) { // SAML

         if (this.validCookies()) {
            this.redirectViaCookies();
         } else {
            this._ssoService.GetSAMLidpURL().then(
               (data) => {
                  this.responseData = data;
                  this.redirect(this.responseData.samlRequest);
               },
               (error) => {
                  // console.log('Landing Page - SAML call failed');
                  // console.dir(error);
                  // TODO: Display an error message
                  this.isRequesting = false;
               }
            );
         }
      } else if (this.loginType === 2) {  // OAUTH2.0

         this._ssoService.GetOAuthConfig().then(
            (data) => {
               // Get Client ID and get JWT token from Google
               this.responseData = data;

               this.authConfig.clientId = data.clientID;
               this.authConfig.issuer = data.issuer;
               this.authConfig.scope = data.scope;
               this.authConfig.redirectUri = data.redirectURI;

               // console.log(this.authConfig);

               this._oauthService.configure(this.authConfig);
               this._oauthService.tokenValidationHandler = new JwksValidationHandler();
               this._oauthService.loadDiscoveryDocumentAndTryLogin();

               // insert the implicit flow here
               this._oauthService.initImplicitFlow();
            },
            (error) => {
               // TODO: Display an error message
               this.isRequesting = false;
            }
         );

         // insert the implicit flow here if we do this all from the front end
         this._oauthService.initImplicitFlow();
      } else {
         // this.message = 'LoginType not Normal';
         this.isRequesting = true;
         this.showError = true;
         this.getLandingPageData();

         this.delay(3000).then(
            result => {
               this.onSignInClick();
               // this.isRequesting = false;
            }
         );
      }
   }

   public delay(ms: number) {
      return new Promise(resolve => setTimeout(resolve, ms));
   }

   // onGuestClick() {
   //    console.log('onGuestClick on Landing Page');
   //    this._router.navigate(['/home']);
   // }

   private redirect(newHref: string) {
      console.log('Redirecting to: ' + newHref);
      window.location.href = newHref;
   }
}
