// app/directives/valid-user.directive.ts
// right now this is just an example of a directive that will allow us to custom validate user input

import { Directive, OnChanges, Input, SimpleChanges } from '@angular/core';
import { Validator, Validators, NG_VALIDATORS, AbstractControl } from '@angular/forms';

@Directive({
    selector: '[valid-user]',
    providers: [{provide: NG_VALIDATORS, useExisting: ValidUserDirective, multi: true}]
})

export class ValidUserDirective implements Validator, OnChanges {
    @Input() userName: string;
    private valFn = Validators.nullValidator;

    ngOnChanges(changes: SimpleChanges): void {

    }

    validate(control: AbstractControl): { [key: string]: any } {
        return this.valFn(control);
    }
}
