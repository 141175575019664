
import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions, Headers } from '@angular/http';

import { Observable } from 'rxjs';




import { AppConfig } from '../config/app.config';

@Injectable()
export class SettingsService {

   public apiControllerUrl = 'Settings/';
   public options: RequestOptions;

   constructor(private _http: Http, private config: AppConfig) {
   }

   private setApiOptions() {
      const headers = new Headers({ 'Content-Type': 'application/json' });
      this.options = new RequestOptions({ headers: headers });
   }

   // public getConfig(): Observable<any> {
   //     if (this.config.IsConfigurationLoaded()) {
   //         return this.config.getConfig();
   //     }
   //     else {
   //         var configPromise = this.config.readConfig();
   //         configPromise.then((data) => {
   //             console.log("Promise complete");
   //             console.log(data);
   //             return Observable.of(data);
   //         },
   //             (error) => {
   //                 console.log("Promise Error");
   //                 console.log(error);
   //                 return Observable.of("");
   //             });
   //     }
   // }

   private extractData(res: Response): string {
      if (res && res.status === 200) {
         const body = res.text;
         return body.toString();
      } else {
         return 'Invalid Request';
      }
   }

   private errorResponse(error: Response | any): string {
      // In a real world app, we might use a remote logging infrastructure
      let errMsg: string;
      if (error instanceof Response) {
         const body = error.json() || '';
         const err = body.error || JSON.stringify(body);
         errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
      } else {
         errMsg = error.message ? error.message : error.toString();
      }

      console.error(errMsg);
      return errMsg;
   }


   // This call will get the district name, login type, and asset management type
   public GetSystemSettings(): Promise<any> {
      const configPromise = this.config.readConfig();
      return configPromise.then(
         (data) => {

            const apiUrl = data.apiUrl;

            const apiUrlRoute = apiUrl + this.apiControllerUrl + 'GetSystemSettings';

            const promise = new Promise((resolve, reject) => {
               this._http.get(apiUrlRoute, this.options).toPromise().then(
                  res => { // Success
                     const resolveData = res.json();
                     resolve(resolveData);
                  },
                  msg => { // Error
                     reject(msg);
                  }
               );
            });
            return promise;

         }, (error) => {

            console.log('Settings Service - GetGuestSetting promise failed');

            return Promise.reject('Failed to read configuration');
         });
   }

   // public GetGuestSetting(): Promise<any> {

   //    // console.log("Settings Service - GetGuestSetting reading Configuration");

   //    let configPromise = this.config.readConfig();
   //    return configPromise.then(
   //       (data) => {

   //          // console.log("Settings Service - GetGuestSetting promise returned ");

   //          let apiUrl = data.apiUrl;

   //          let apiUrlRoute = apiUrl + this.apiControllerUrl + 'IsGuestActive';

   //          let promise = new Promise((resolve, reject) => {
   //             this._http.get(apiUrlRoute, this.options).toPromise().then(
   //                res => { // Success
   //                   const resolveData = res.json();
   //                   resolve(resolveData);
   //                },
   //                msg => { // Error
   //                   reject(msg);
   //                }
   //             );
   //          });
   //          return promise;

   //       }, (error) => {

   //          console.log('Settings Service - GetGuestSetting promise failed');

   //          return Promise.reject('Failed to read configuration');
   //       });
   // }

}
