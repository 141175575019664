﻿import {Component, Input, OnDestroy} from '@angular/core';

@Component({
    selector: 'loading-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.css']
})
export class SpinnerComponent implements OnDestroy {
    private currentTimeout: NodeJS.Timer;
    private isDelayedRunning: boolean = false;

    @Input()
    public delay: number = 3000;

    @Input()
    public set isRunning(value: boolean) {

        if (!value) {
            this.cancelTimeout();
            this.isDelayedRunning = false;
            return;
        }

        if (this.currentTimeout) {
            return;
        }

        this.currentTimeout = setTimeout(() => {
            this.isDelayedRunning = value;
            this.getMessage();
            this.cancelTimeout();
        }, this.delay);
    }

    public messages: any[] = [
        { message: 'Loading ...' }
    ];

    public uiMessage: any = { message: 'Loading ...' };
    public getMessage() {

        let num = Math.floor(Math.random() * (this.messages.length));

        this.uiMessage = this.messages[num];

    }

    private cancelTimeout(): void {
        clearTimeout(this.currentTimeout);
        this.currentTimeout = undefined;
    }

    ngOnDestroy(): any {
        this.cancelTimeout();
    }
}
