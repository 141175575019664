import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Observable } from 'rxjs';
import { UriParse } from '../services/uriParse.service'





import 'rxjs/Rx';

@Injectable()
export class AppConfig {

   public isLoaded: boolean = false;
   public apiUrl: string;
   private _env: any;

   constructor(private _http: Http) {
      //   console.log("AppConfig Constructor");
   }

   public readConfig(): Promise<any> {

      if (!this.isLoaded) {

         const promise = new Promise((resolve, reject) => {
            this._http.get('./assets/config.json')
               .toPromise()
               .then(
                  res => { // Success
                     const data = res.json();
                     this.apiUrl = UriParse.parseUri(data.apiUrl);
                     // console.log(data);
                     this.isLoaded = true;

                     resolve({ apiUrl: this.apiUrl });
                  },
                  msg => { // Error
                     reject(msg);
                  });
         });
         return promise;

      } else {
         return Promise.resolve({ apiUrl: this.apiUrl });
      }
   }

   /**
    * Use to get the data found in the second file (config file)
    */
   public getConfig(): Promise<any> {
      return this.readConfig();
   }

   public IsConfigurationLoaded(): boolean {
      return this.isLoaded;
   }

   /**
    * Use to get the data found in the first file (env file)
    */
   public getEnv(key: any) {
      return this._env[key];
   }

   /**
    * This method:
    *   a) Loads "env.json" to get the current working environment (e.g.: 'production', 'development')
    *   b) Loads "config.[env].json" to get all env's variables (e.g.: 'config.development.json')
    */
   // public load() {

   //     console.log('Starting Configuration Load');

   //     return new Promise((resolve, reject) => {

   //         let configFile = './assets/env.json';

   //         console.log('Getting content: this._http.get(\'' + configFile + '\')');

   //         this._http.get(configFile)
   //             .map(res => res.json())
   //             .catch((error: any) => {
   //                 console.error('Configuration file \'' + configFile + '\' could not be read');
   //                 console.error(error);
   //                 resolve(error);
   //                 return Observable.throw(error); // return Observable.throw('Server error: Get Env.json');
   //             })
   //             .subscribe((env_data) => {
   //                 this._env = env_data;
   //                 let request: any;

   //                 configFile = './assets/config.' + env_data.env + '.json';

   //                 console.log('Getting content: this._http.get(\'' + configFile + '\')');

   //                 request = this._http.get(configFile);

   //                 if (request) {
   //                     request
   //                         .map((res: any) => res.json())
   //                         .catch((error: any) => {
   //                             console.error('Error reading file \'' + configFile + '\'.');
   //                             console.error(error);
   //                             resolve(error);
   //                             return Observable.throw(error); // return Observable.throw('Server error: Get config.ev.json');
   //                         })
   //                         .subscribe((responseData: any) => {
   //                             this._config = responseData;
   //                             this.isLoaded = true;
   //                             // console.log(responseData);
   //                             resolve(true);
   //                         });
   //                 } else {
   //                     // console.log(env_data.env);
   //                     console.error('Env config file is not valid');
   //                     resolve(true);
   //                 }
   //             });
   //     });
   // }
}