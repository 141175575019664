export class UriParse {

   /**
    * Parse the Uri as a relative path or return the uri
    * @param uri
    */
   public static parseUri(uri: string): string {

      let parsedUri = UriParse.validateUri(uri);

      if (parsedUri !== null) {
         // console.log('URI: ' + uri);
         // console.log(parsedUri);

         // Check if the apiUrl is an absolute or relative path
         if (parsedUri.isRelative) {

            let parsedUrl: string;
            let hrefVal = window.location.origin;

            if (hrefVal.lastIndexOf(':') > 6) {
               hrefVal = hrefVal.substring(0, hrefVal.lastIndexOf(":"));
            }

            if (hrefVal.lastIndexOf('/') == (hrefVal.length - 1)) {
               hrefVal = hrefVal.substring(0, hrefVal.lastIndexOf("/"));
            }

            // console.log('Current Uri: ' + hrefVal);

            if (uri.indexOf(':') < 0) {
               if (uri.indexOf('/') == 0) {
                  parsedUrl = hrefVal + uri;
               } else {
                  parsedUrl = hrefVal + "/" + uri;
               }
            } else {
               parsedUrl = hrefVal + uri;
            }

            // console.log('Relative URI: ' + parsedUrl);

            return parsedUrl;
         } else {
            return uri;
         }
      } else {
         console.log('Uri: ' + uri + ' is invalid');
      }
   }

   protected static validateUri(uri: string): any {

      let protocol: string;
      let host: string;
      let hostname: string;
      let port: string;
      let path: string;
      let query: string;
      let isRelative: boolean = false; // Defaults to not relative.
      let hasProtocol: boolean = false;

      let indexOfProtocolSeparator: number = -1;
      let beginningOfPort: number = -1;
      let beginningOfPath = -1;
      let beginningOfQuery = -1;
      let remainderUri: string;
      let hostSplit: Array<string>;
      let leftOfPath: string;

      if (uri === undefined || uri === null || uri == "") {
         return null;
      }

      remainderUri = uri; // Put the uri into a "workable variable"

      indexOfProtocolSeparator = remainderUri.indexOf("://");
      if (indexOfProtocolSeparator == 0) { // Cannot be 0, invalid uri
         return null;

      } else if (indexOfProtocolSeparator > 0) {
         protocol = remainderUri.substring(0, indexOfProtocolSeparator);
         remainderUri = remainderUri.substring(indexOfProtocolSeparator + 3);
         hasProtocol = true;

      } else {
         // No protocol specified, default to http
         protocol = 'http';

      }

      beginningOfPath = remainderUri.indexOf("/");
      beginningOfQuery = remainderUri.indexOf("?");
      beginningOfPort = remainderUri.indexOf(":");

      if (beginningOfQuery == 0 || // uri cannot start with a query
         (beginningOfPath > beginningOfQuery && beginningOfQuery > 0) || // query cannot start before path
         (beginningOfPort > beginningOfPath && beginningOfPath > -1) /* Port indicator cannot exist after start of path*/) {
         return null;
      } else {

         if (hasProtocol) { // Cannot be a relative path
            if (beginningOfPath == 0) { // host cannot be a relative path
               return null;
            }
            else if (beginningOfPath > 0) {
               host = remainderUri.substring(0, beginningOfPath);
            }
            else {
               host = remainderUri;
            }

            hostname = host;

            if (beginningOfPort == 0) {
               return null; // Since the uri has a protocol, host cannot start with the port indicator

            } else if (beginningOfPort > 0) {
               // has a port number, separate it out
               port = host.substring(beginningOfPort + 1);
               if (!UriParse.isInteger(port)) {
                  return null; //port must be an integer

               }

               hostname = host.substring(0, beginningOfPort);
            }
            else {
               port = "";
            }

            //Validate that the host is correct
            hostSplit = hostname.split(".");
            if (hostSplit.length == 1) {
               if (hostSplit[0] != "localhost") {
                  return null; //if the hostname is not localhost only it is invalid.

               }
            }

            if (beginningOfPath > 0) {
               if (beginningOfQuery > 0) {
                  query = remainderUri.substring(beginningOfQuery + 1);
                  path = remainderUri.substring(beginningOfPath, beginningOfQuery);
               }
               else {
                  path = remainderUri.substring(beginningOfPath);
                  query = "";
               }
            }
            else {
               path = "";
               query = "";
            }

         }
         else { // Does NOT have a protocol
            if (beginningOfPath == 0) { // starts with a slash, it is a relative Path, Cannot have a port, host and hostname stay empty
               host = "";
               hostname = "";
               port = "";
               path = remainderUri;
               isRelative = true;
            } else { // Does not start with a separator, check if the uri is a domain or a path
               if (beginningOfPath > 0) {
                  leftOfPath = remainderUri.substring(0, beginningOfPath);
               }
               else {
                  leftOfPath = remainderUri
               }

               if (leftOfPath.indexOf(".") < 0) { //it is a not valid domain

                  //check for port
                  if (beginningOfPort == 0) { //host is a port number
                     port = leftOfPath.substring(1);
                     if (!UriParse.isInteger(port)) {
                        return null; //port must be an integer
                     }
                     host = leftOfPath;
                     hostname = "";
                     if (beginningOfPath > 0) {
                        path = remainderUri.substring(beginningOfPath);
                     }
                     else {
                        path = "";
                     }
                     isRelative = true;
                  }
                  else if (beginningOfPort > 0) {
                     //has a port number, separate it out
                     port = leftOfPath.substring(beginningOfPort + 1);
                     if (!UriParse.isInteger(port)) {
                        return null; //port must be an integer
                     }
                     host = leftOfPath;
                     hostname = leftOfPath.substring(0, beginningOfPort);
                     if (beginningOfPath > 0) {
                        path = remainderUri.substring(beginningOfPath);
                     }
                     else {
                        path = "";
                     }
                  } else { // no port

                     port = "";

                     if (leftOfPath == "localhost") {
                        host = leftOfPath;
                        hostname = host;
                        if (beginningOfPath > 0) {
                           path = remainderUri.substring(beginningOfPath);
                        }
                        else {
                           path = "";
                        }
                     }
                     else {
                        host = "";
                        hostname = "";
                        path = remainderUri;
                        isRelative = true;
                     }
                  }

               }
               else { // Maybe a valid domain
                  host = leftOfPath;

                  if (beginningOfPort == 0) { //host is a port number
                     port = leftOfPath.substring(1);
                     if (!UriParse.isInteger(port)) {
                        return null; //port must be an integer
                     }
                     host = leftOfPath;
                     hostname = "";
                     if (beginningOfPath > 0) {
                        path = remainderUri.substring(beginningOfPath);
                     }
                     else {
                        path = "";
                     }
                     isRelative = true;
                  }
                  else if (beginningOfPort > 0) {
                     //has a port number, separate it out
                     port = leftOfPath.substring(beginningOfPort + 1);
                     if (!UriParse.isInteger(port)) {
                        return null; //port must be an integer
                     }
                     host = leftOfPath;
                     hostname = leftOfPath.substring(0, beginningOfPort);
                     if (beginningOfPath > 0) {
                        path = remainderUri.substring(beginningOfPath);
                     }
                     else {
                        path = "";
                     }
                  } else { // no port

                     port = "";
                     host = leftOfPath;
                     hostname = host;
                     if (beginningOfPath > 0) {
                        path = remainderUri.substring(beginningOfPath);
                     }
                     else {
                        path = "";
                     }
                  }
               }
            }

            //Separate the path from the query
            beginningOfQuery = path.indexOf("?");

            if (beginningOfQuery > 0) {
               query = path.substring(beginningOfQuery + 1);
               path = path.substring(0, beginningOfQuery);
            }
            else {
               //path = path.substring(beginningOfPath + 1);
               query = "";
            }
         }
      }

      let parsedUri = {
         uri: uri,
         isRelative: isRelative,
         protocol: protocol,
         host: host,
         hostname: hostname,
         port: port,
         path: path,
         query: query
      };

      return parsedUri;
   }

   public static isInteger(n): boolean {
      return !isNaN(parseInt(n)) && isFinite(n);
   }
}