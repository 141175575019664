
import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions, Headers } from '@angular/http';

// import { Observable } from 'rxjs/Observable';



// import { ILogin } from '../models/login';
import { AppConfig } from '../config/app.config';

@Injectable()
export class SSOService {

   public apiUrl = '';
   public apiControllerUrl = 'SSO/';
   // private apiconfig: AppConfig;
   public options: RequestOptions;

   constructor(private _http: Http, private config: AppConfig) {
      // this.apiconfig = config;
   }

   // SSO Methods
   public GetSAMLidpURL(): Promise<any> {
      // console.log("SSO Service - GetSAMLURL reading Configuration");

      return this.config.readConfig().then(
         (data) => {
            const apiUrlRoute = data.apiUrl + this.apiControllerUrl + 'SAMLResquest';

            const promise = new Promise((resolve, reject) => {
               this._http.get(apiUrlRoute, this.options).toPromise().then(
                  res => { // Success
                     const resolvedData = res.json();
                     resolve(resolvedData);
                  },
                  msg => { // Error
                     reject(msg);
                  }
               );
            });
            return promise;

         }, (error) => {
            console.log('SSO Service - GetLoginType promise failed');
            return Promise.reject('Failed to Redirect to SAML');
         });
   }

   public VerifySAML(token: string, signature: string): Promise<any> {
      // console.log("SSO Service - GetSAMLURL reading Configuration");

      return this.config.readConfig().then(
         (data) => {
            const apiUrlRoute = data.apiUrl + this.apiControllerUrl + 'ValidateSAML';

            const body = { SAMLResponse: token, SAMLSignature: signature };

            const promise = new Promise((resolve, reject) => {
               this._http.post(apiUrlRoute, body, this.options).toPromise().then(
                  res => {
                     if (res && res.status === 200) {
                        // Success
                        const reslvedData = res.json();
                        resolve(reslvedData);
                     } else {
                        reject('SSO Error.');
                     }
                  },
                  msg => { // Error
                     const errormsg = msg.json();
                     reject(errormsg);
                  }
               );
            });
            return promise;

         }, (error) => {
            console.log('SSO Service - GetLoginType promise failed');
            return Promise.reject('Failed to Redirect to SAML');
         });
   }

   public VerifyOAuth(token: any): Promise<any> {

      return this.config.readConfig().then(
         (data) => {
            const apiUrlRoute = data.apiUrl + this.apiControllerUrl + 'ValidateOAuth';
            const promise = new Promise((resolve, reject) => {
               this._http.post(apiUrlRoute, token, this.options).toPromise().then(
                  res => {
                     if (res && res.status === 200) {
                        // Success
                        const resolvedData = res.json();
                        resolve(resolvedData);
                     } else {
                        reject('SSO Error.');
                     }
                  },
                  msg => { // Error
                     const errormsg = msg.json();
                     reject(errormsg);
                  }
               );
            });
            return promise;

         }, (error) => {
            console.log('SSO Service - GetLoginType promise failed');
            return Promise.reject('Failed to Redirect to SAML');
         });
   }

   public GetOAuthConfig(): Promise<any> {

      return this.config.readConfig().then(
         (data) => {

            const apiUrlRoute = data.apiUrl + this.apiControllerUrl + 'GetOAuthConfig';

            const promise = new Promise((resolve, reject) => {
               this._http.get(apiUrlRoute, this.options).toPromise().then(
                  res => { // Success
                     const resolvedData = res.json();
                     resolve(resolvedData);
                  },
                  msg => { // Error
                     reject(msg);
                  }
               );
            });
            return promise;

         }, (error) => {
            console.log('SSO Service - GetLoginType promise failed');
            return Promise.reject('Failed to Redirect to SAML');
         });
   }
}
