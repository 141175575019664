import { Component, OnInit } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { BaseComponent } from '../base-component/base-component.component';

import { LoginService } from '../../services/login.service';
import { UserService } from '../../services/user-data.service';

@Component({
    selector: 'tipweb-id',
    templateUrl: './tipweb-id.component.html',
    styleUrls: ['./tipweb-id.component.css'],
    providers: [UserService]
})

export class TipWebID extends BaseComponent implements OnInit {
    public tipwebIDForm: FormGroup;
    public tipWebIdData: boolean = false;
    public staffStudentChange: any = {
        userID: null,
        entityID: '',
        hayesToken: '',
        refreshToken: ''
    };

    public userID;
    public verificationResult: any;
    public isRequesting = false;

    constructor(
        private _route: ActivatedRoute,
        private _loginService: LoginService,
        private _userService: UserService,
        private _fb: FormBuilder,
        private _router: Router) {
        super();
        this._route.params.forEach((params: Params) => {
            if (params['userId']) {
                this.staffStudentChange.userID = params['userId'];
            }
        });

        if (!this._loginService.hayesToken || !this.staffStudentChange.userID) {
            // this._router.navigate(['/landing']);
            this.closePopUpOutlet();
        } else {
            this.staffStudentChange.hayesToken = this._loginService.hayesToken;
        }
    }

    ngOnInit() {
        this.buildForm();
    }

    buildForm(): void {
        this.tipwebIDForm = this._fb.group({
            'tipWebStaffStudentID': [this.staffStudentChange.entityID, [Validators.required]],
            'userId': [this.staffStudentChange.userId]
        });

        this.tipwebIDForm.valueChanges.subscribe(data => this.onValueChanged(data));
    }

    public onValueChanged(data?: any) {
        if (!this.tipwebIDForm) {
            return;
        }

        const form = this.tipwebIDForm;

        if (form.controls['tipWebStaffStudentID'].value) {
            this.tipWebIdData = true;
        } else {
            this.tipWebIdData = false;
        }
    }

    public skipIDtoGetHelp() {
        this.staffStudentChange.entityID = '';
        this.updateIDGoToGetHelp();
    }

    public updateIDGoToGetHelp() {
        this.isRequesting = true;

        this._userService.updateStaffStudentID(this.staffStudentChange).then(
            data => {
                window.location.href = this._loginService.redirectUrl;
            },
            error => {
                const errorMessage = error.json();

                for (let message in errorMessage) {
                    console.log(errorMessage[message]);
                }
                window.location.href = this._loginService.redirectUrl;
            });
    }

    public closePopUpOutlet() {
        this._router.navigate([{ outlets: { popUpOutlet: null } }]);
    }

    public onSubmit(): void {

        this.isRequesting = true;
        this.staffStudentChange.entityID = this.tipwebIDForm.controls['tipWebStaffStudentID'].value;

        this._userService.verifyStaffStudentID(this.staffStudentChange).then(
            data => {
                this.verificationResult = data;

                if (this.verificationResult == 2) { // Valid ID and Updated on table
                    window.location.href = this._loginService.redirectUrl;
                } else {
                    this.isRequesting = false;
                }
            },
            error => {
                console.log (this.verificationResult);
            }
        );
    }
}