import { Component, OnInit } from '@angular/core';
import { Params, ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../base-component/base-component.component';

import { EmailOptOutService } from '../../services/email-optout.service';

import { IUserAccount } from '../../models/user-account';

@Component({
   selector: 'email-optout',
   templateUrl: './email-optout.component.html',
   styleUrls: ['./email-optout.component.css'],
   providers: [EmailOptOutService]
})

export class EmailOptOutComponent extends BaseComponent implements OnInit {

   public encryptedCode: string = null;
   public emailAddress: string = null;
   public unsubscribed = false;
   public description: string = '';
   public action: string = '';
   public confirmationMessage: string = '';
   public unattendedUser = false;
   private user: IUserAccount = null;
   public isRequesting: boolean = false;
   public hasError: boolean = false;
   public errorMessage: string = '';
   public actionComplete: boolean = false;

   constructor(
      private _emailOptOutService: EmailOptOutService,
      private _route: ActivatedRoute
   ) {
      super();
   }

   ngOnInit() {

      this.isRequesting = true;

      if (this.emailAddress == null) {

         this._route.params.forEach((params: Params) => {
            if (params['code']) {
               this.encryptedCode = params['code'];
               this._emailOptOutService.GetUserDetails(this.encryptedCode).then(
                  (response) => {
                     if (response) {
                        this.user = response;
                        this.emailAddress = this.user.emailAddress;
                        this.unsubscribed = !this.user.optInEmails;
                        this.description = this.unsubscribed ?
                           'Please confirm that you would like to register your email address,' + this.emailAddress + ' to receive future help desk ticket notifications from GetHelp.' :
                           'Please confirm that you would like to unsubscribe your email address, ' + this.emailAddress + ', from future help desk ticket notifications from GetHelp.';
                        this.action = this.unsubscribed ? 'Subcribe' : 'Unsubscribe';
                        this.confirmationMessage = this.unsubscribed ?
                           'You have been subscribed to GetHelp emails' :
                           'You have been unsubscribed from GetHelp emails';
                        this.unattendedUser = this.user.unattendedUser;
                        this.isRequesting = false;
                        this.hasError = false;
                     } else {
                        this.hasError = true;
                        this.errorMessage = 'Unable to get User Information from email';
                        this.isRequesting = false;
                     }
                  },
                  (error) => {
                     this.hasError = true;
                     this.errorMessage = 'Unable to get User Information from email';
                     this.isRequesting = false;
                     console.log(error);
                  }
               );
            }
         });

         if (!this.encryptedCode) {
            this.hasError = true;
            this.errorMessage = 'Unable to determine Email Address to unsubscribe';
         }
      }
   }

   public unsubscribeClicked() {
      // console.log('Unsubscribe clicked');
      this.isRequesting = true;
      this._emailOptOutService.UpdateEmailOptIn(this.encryptedCode, this.unsubscribed).then(
         (response) => {
            if (response) {
               this.hasError = false;
               this.unsubscribed = !this.unsubscribed;
               this.actionComplete = true;
               this.isRequesting = false;
            } else {
               this.hasError = true;
               this.errorMessage = 'Unable to change OptIn settings';
               this.isRequesting = false;
            }
         },
         (error) => {
            this.hasError = true;
            this.errorMessage = 'An error occurred while changing OptIn settings';
            console.log(error);
            this.isRequesting = false;
         }
      );
   }

}
