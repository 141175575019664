import { Injectable } from '@angular/core';
// import { HttpClient } from '@angular/common/http';
import { Http, Response, RequestOptions, Headers } from '@angular/http';

import { IUserAccount } from '../models/user-account';
import { AppConfig } from '../config/app.config';
// import { config } from 'rxjs';

@Injectable()
export class EmailOptOutService {

   //#region Properties and Variables

   public apiRoutes = {
      GetUserByEncryptedCode: 'User/GetUserByEncryptedCode/{encryptedCode}',
      EmailOptOut: 'User/EmailOptOut/{encryptedCode}/{optOut}',

   };

   //#endregion Properties and Variables

   //#region Constructor

   constructor(
      private _http: Http,
      private config: AppConfig
   ) { }

   //#endregion Constructor

   //#region Utility Methods

   private readConfig(): Promise<any> {
      return this.config.readConfig();
   }

   private extractTextResult(res: Response) {
      if (res && res.status === 200) {
         const body = res.text();
         return body;
      } else {
         return 'Invalid Verification Request';
      }
   }


   //#endregion Utility Methods

   //#region Get Methods

   public GetUserDetails(encryptedCode: string): Promise<IUserAccount> {
      const configPromise = this.config.readConfig();
      return this.readConfig().then(
         (data) => {
            const apiUrlRoute = data.apiUrl + this.apiRoutes.GetUserByEncryptedCode.replace('{encryptedCode}', encryptedCode);

            const headers = new Headers({ 'Content-Type': 'application/json' });

            const options = new RequestOptions({ headers: headers });

            return this._http.get(apiUrlRoute, options).toPromise().then(
               (res) => {
                  const user = this.extractUser(res);
                  return Promise.resolve(user);
               },
               (msg) => {
                  return Promise.reject(msg);
               });
         },
         (error) => {
            return Promise.reject(error);
         }
      );
   }

   private extractUser(response: any): IUserAccount {

      const body = response.json();
      if (body) {
         const userData: IUserAccount = {
            userID: body.userID,
            loginName: body.loginName,
            emailAddress: body.emailAddress,
            active: body.active,
            userTypeName: body.userTypeName,
            createDate: body.createDate,
            createBy: body.createBy,
            lastModifiedDate: body.lastModifiedBy,
            lastModifiedBy: body.lastModifiedBy,
            lastLoginDate: body.lastLoginDate,
            unattendedUser: body.unattendedUser,
            optInEmails: body.optInEmails
         };
         return userData;
      } else {
         return null;
      }
   }

   public UpdateEmailOptIn(encryptedCode: string, optIn: boolean): Promise<boolean> {
      return this.readConfig().then(
         (data) => {
            const apiUrlRoute = data.apiUrl + this.apiRoutes.EmailOptOut.replace('{encryptedCode}', encryptedCode).replace('{optOut}', (!optIn).toString());

            const headers = new Headers({ 'Content-Type': 'application/json' });

            const options = new RequestOptions({ headers: headers });

            return this._http.put(apiUrlRoute, options).toPromise().then(
               (res) => {
                  if (res) {
                     return Promise.resolve(true);
                  } else {
                     return Promise.resolve(false);
                  }
               },
               (error) => {
                  console.log(error);
                  return Promise.reject(false);
               });
         },
         (error) => {
            console.log(error);
            return Promise.reject(false);
         }
      );

   }

   //#endregion Get Methods

}
