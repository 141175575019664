import { Component, OnInit, AfterViewChecked, HostListener } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';



import { BaseComponent } from '../base-component/base-component.component';

import { ILogin } from '../../models/login';
import { LoginService } from '../../services/login.service';
import { SettingsService } from '../../services/settings.service';

import { environment } from '../../../environments/environment';


@Component({
   selector: 'home',
   templateUrl: './home.component.html',
   styleUrls: ['./home.component.css'],
   providers: [SettingsService]
})
export class HomeComponent extends BaseComponent implements OnInit, AfterViewChecked {

   isRequesting: boolean = true;
   finalDestination: string;
   isNewForm: boolean = true;
   innerWidth: number;
   assetManagementType: number;
   districtName: string;
   firebaseCollection: string;

   loginForm: FormGroup;

   public version: string = environment.version;
   public expireCookieDate: Date = new Date();

   @HostListener('window:resize', ['$event'])
   onResize(event) {
      this.innerWidth = window.innerWidth;
   }

   Login: ILogin = {
      loginName: null,
      userID: null,
      password: null,
      requestEntityID: false,
      userTypeID: null,
      routeUrl: null,
      returnUrl: null,
      hayesToken: null,
      refreshToken: null
   };

   formErrors = {
      'loginName': '',
      'password': '',
      'login': ''
   };

   validationMessages = {
      'loginName': { 'required': '' },
      'password': { 'required': '' }
   };

   public responseData: ILogin;

   constructor(
      private _fb: FormBuilder,
      private _route: ActivatedRoute,
      private _router: Router,
      private _loginService: LoginService,
      private _settingsService: SettingsService,
      private _titleService: Title,
      private _cookieService: CookieService) {
      super();
      this.expireCookieDate = new Date();
      this.expireCookieDate.setDate(this.expireCookieDate.getDate() + 7);
   }

   ngAfterViewChecked() {
      const newTitle = `Help Desk - ${this.version} - Login`;
      this._titleService.setTitle(newTitle);
   }

   ngOnInit(): void {
      this.innerWidth = window.innerWidth;

      if (this._cookieService.check('finalDestination')) {
         this.finalDestination = this._cookieService.get('finalDestination');
      } else {
         this.finalDestination = this._route.snapshot.queryParams.d ? this._route.snapshot.queryParams.d : undefined;

         if (this.finalDestination !== undefined) {

            this._cookieService.set('finalDestination', this.finalDestination, this.expireCookieDate, '/');
         }
      }

      this.getSystemSettings();
      this.buildForm();
   }

   // Get login type and asset management type
   getSystemSettings(): void {
      this._settingsService.GetSystemSettings().then(
         (data) => {
            this.assetManagementType = data.assetManagementType;
            if (data && data.loginType !== 0) {
               this._router.navigate(['/landing']);
            }

            this.isRequesting = false;
         },
         (error) => {
            this.isRequesting = false;
         }
      );
   }

   buildForm(): void {
      this.loginForm = this._fb.group({
         'loginName': [this.Login.loginName, [Validators.required]],
         'password': [this.Login.password, [Validators.required]]
      });

      this.loginForm.valueChanges.subscribe(data => this.onValueChanged(data));

      this.onValueChanged();
   }

   onValueChanged(data?: any) {
      if (!this.loginForm) {
         return;
      }
   }

   private setRedirectURL(newHref: string) {
      // If the asset management cookie is not set then run the settings service get system settings function
      // then run the normal logic, otherwise just run the logic to encode the Uri and reroute
      if (this._cookieService.get('assetManagmentType') === '') {
         this._settingsService.GetSystemSettings().then(
            (data) => {
               this.assetManagementType = data.assetManagementType;
               this._cookieService.set('assetManagementType', this.assetManagementType.toString(), this.expireCookieDate, '/');
               this.districtName = data.districtName;
               this._cookieService.set('districtName', this.districtName, this.expireCookieDate, '/');
               this.firebaseCollection = data.firebaseNotificationsCollection;
               this._cookieService.set('firebaseCollection', this.firebaseCollection, this.expireCookieDate, '/');
               this.createURI(newHref);
            },
            (error) => {
               console.log('Landing Page - Get System Settings call failed');
               console.dir(error);
            }
         );
      } else {
         this.assetManagementType = +this._cookieService.get('assetManagementType');
         this.districtName = this._cookieService.get('districtName');
         this.firebaseCollection = this._cookieService.get('firebaseCollection');
         this.createURI(newHref);
      }
   }

   createURI(newHref: string) {
      const api = encodeURIComponent(this._loginService.getApiUrl());

      if (newHref && newHref.includes('localhost')) {
         const d = this.finalDestination ? '&d=' + this.finalDestination : '';
         const encodedUri = encodeURI(newHref +
            'splash?userName=' + this.responseData.loginName +
            '&userId=' + this.responseData.userID +
            '&userType=' + this.responseData.userTypeID +
            '&districtName=' + this.districtName +
            '&assetManagementType=' + this.assetManagementType.toString() +
            '&firebaseCollection=' + this.firebaseCollection +
            '&apiToken=' + this.responseData.hayesToken +
            '&refreshToken=' + this.responseData.refreshToken) +
            '&login=' + this.responseData.returnUrl +
            '&api=' + api + d;

         this._loginService.redirectUrl = encodedUri;
      } else {
         // Add data to local storage while login to non-prod/prod environment
         const d = this.finalDestination ? this.finalDestination : '';
         let localData = {
            jwt: this.responseData.hayesToken,
            refreshToken: this.responseData.refreshToken,
            userName: this.responseData.loginName,
            userId: this.responseData.userID,
            userType: this.responseData.userTypeID,
            districtName: this.districtName,
            assetManagementType: this.assetManagementType.toString(),
            firebaseCollection: this.firebaseCollection,
            login: this.responseData.returnUrl,
            api: api,
            d: d,
         };
         this._loginService.setLocalData(localData);
         const encodedUri = encodeURI(newHref + 'splash?userId=' + this.responseData.userID);
         this._loginService.redirectUrl = encodedUri;
      }

      this._loginService.hayesToken = this.responseData.hayesToken;
      this._loginService.refreshToken = this.responseData.refreshToken;

      if (this.responseData.requestEntityID === true && this.assetManagementType === 1) {
         this._router.navigate([{ outlets: { popUpOutlet: 'tipweb-id/' + this.responseData.userID } }]);
      } else {
         window.location.href = this._loginService.redirectUrl;
      }
   }

   private stopRefreshing() {
      this.isRequesting = false;
   }

   public onLogIn(): void {
      this.isRequesting = true;
      this.Login = this.loginForm.value;

      this._loginService.Login(this.Login).then(
         data => {
            this.responseData = data;
            this._cookieService.deleteAll('/');
            this._cookieService.set('hhduiUrl', this.responseData.routeUrl, this.expireCookieDate, '/');
            this.setRedirectURL(this.responseData.routeUrl);
         },
         error => {
            this.formErrors.login = error;
            this.stopRefreshing();
         }
      );
   }
}
