import { Component, ReflectiveInjector } from '@angular/core';
import { TelemetryService } from '../../services/telemetry-tracker.service';

@Component({
    template: '',
    // providers: [ConfigService]
})
export class BaseComponent {

    private _telemetryService: TelemetryService;

    constructor() {
        // Manually retrieve the monitoring service from the injector
        // so that constructor has no dependencies that must be passed in from child
        const injector = ReflectiveInjector.resolveAndCreate([
            TelemetryService
        ]);
        this._telemetryService = injector.get(TelemetryService);
        this.logNavigation();
    }

    private logNavigation() {
        this._telemetryService.logPageView();
    }
}
